
import { Vue, Component, Prop, Watch } from "nuxt-property-decorator";
import { ValidationProvider } from "vee-validate";
import config from "@/config";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class VCropImageUpload2WithValidation extends Vue {
  files: any = [];
  innerValue: string = "";
  showUpload = false;
  showMessageUploadSuccess = false;
  intervalUploadSuccess?: NodeJS.Timeout;
  doneInit = false;

  @Prop({ type: [Object, String], default: "" }) rules;
  @Prop({ type: String }) value;

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
    this.$nextTick(() => {
      this.doneInit = true;
    });
  }
  get getObjectLanguage() {
    return config.language[this.$i18n.locale];
  }
  get getBaseUrl() {
    return config.baseURL + "/api/v1/storages";
  }
  fnInput(_val) {
    this.showUpload = _val;
  }
  @Watch("innerValue")
  onInnerValueChanged(newVal: string, oldVal: string) {
    if (this.doneInit) {
      this.$emit("input", newVal);
    }
  }
  @Watch("value")
  onValueChanged(newVal: string, oldVal: string) {
    this.innerValue = newVal;
  }
  toggleShow() {
    this.showUpload = !this.showUpload;
    this.$nextTick(() => {
      //(this.$refs['uploadImg'] as Vue)?.$el?.querySelector("input[type='file'")?.setAttribute('accept', 'image/*');
    });
  }
  cropSuccess(imgDataUrl, field) {
    (this.$refs["uploadImg"] as Vue)?.$el
      ?.querySelector(".vicp-operate-btn")
      ?.setAttribute("disabled", "disabled");
    (this.$refs["uploadImg"] as Vue)?.$el
      ?.querySelector(".vicp-operate-btn")
      ?.setAttribute("style", "pointer-events: none");
    this.innerValue = imgDataUrl;
  }
  cropUploadSuccess(jsonData, field) {
    this.innerValue = jsonData.data;
    this.showUpload = false;
    this.showMessageUploadSuccess = true;
    this.intervalUploadSuccess = setInterval(() => {
      if (this.showMessageUploadSuccess) {
        this.showMessageUploadSuccess = false;
        if (this.intervalUploadSuccess) {
          clearInterval(this.intervalUploadSuccess);
        }
      }
    }, 30000);
  }
  cropUploadFail(status, field) {
    this.innerValue = "";
  }
  async deleteImage() {
    this.$axios
      .delete("api/v1/storages", {
        params: {
          url: this.innerValue,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.innerValue = "";
        }
      });
  }
  get sizeConfig() {
    const _width = (this.$attrs.size as any)?.width ?? 200;
    const _height = (this.$attrs.size as any)?.height ?? 200;
    return {
      width: _width,
      height: _height,
      Hratio: _height / _height,
      Wratio: _width / _height,
    };
  }
  onDrop(e) {
    this.toggleShow();
    e.stopPropagation();
    e.preventDefault();
    this.$nextTick(() => {
      (this.$refs["uploadImg"] as any).handleChange(e);
    });
  }
  onDrag() {}
  beforeDestroy() {
    if (this.intervalUploadSuccess) {
      clearInterval(this.intervalUploadSuccess);
    }
  }
}
