var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("textarea", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.innerValue,
        expression: "innerValue",
      },
    ],
    staticClass: "d-block overflow-hidden",
    staticStyle: {
      "-moz-box-sizing": "border-box",
      "-webkit-box-sizing": "border-box",
    },
    style: _vm.computedStyles,
    domProps: { value: _vm.innerValue },
    on: {
      focus: _vm.resizeTextarea,
      keyup: _vm.resizeTextarea,
      keydown: _vm.resizeTextarea,
      keypress: _vm.resizeTextarea,
      paste: _vm.resizeTextarea,
      input: function ($event) {
        if ($event.target.composing) return
        _vm.innerValue = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }