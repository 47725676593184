var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationProvider", {
    attrs: { name: _vm.$attrs.name, rules: _vm.rules, tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors, flags }) {
          return [
            !_vm.$attrs.isHalf
              ? _c("div", [
                  _vm.$attrs.label
                    ? _c("div", { staticClass: "mb-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "label",
                            style: {
                              color: _vm.$attrs.color || null,
                              fontSize: _vm.$attrs.fontSize || null,
                            },
                          },
                          [_vm._v("\n        " + _vm._s(_vm.$attrs.label))]
                        ),
                        _vm._v(" "),
                        _vm.checkRequired
                          ? _c(
                              "span",
                              {
                                staticClass: "label",
                                staticStyle: { color: "#f46414" },
                              },
                              [_vm._v("*")]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form-group mb-0 __selected",
                      staticStyle: { background: "none" },
                    },
                    [
                      _c(
                        "client-only",
                        [
                          _c(
                            "v-select",
                            {
                              ref: "select",
                              staticClass: "ellipsedd",
                              attrs: {
                                label: _vm.$attrs["option-label"],
                                reduce: _vm.$attrs.reduce,
                                options: _vm.$attrs.options,
                                placeholder: _vm.$t("common.select"),
                                disabled: _vm.$attrs.disabled || false,
                                selectable: _vm.$attrs.selectable,
                                clearable: _vm.$attrs.clearable,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.optionSelected($event, flags)
                                },
                                "option:selected": function ($event) {
                                  return _vm.optionSelected($event, flags)
                                },
                                "search:focus": _vm.onFocus,
                              },
                              nativeOn: {
                                mouseenter: function ($event) {
                                  return _vm.mouseEnter.apply(null, arguments)
                                },
                                mouseleave: function ($event) {
                                  return _vm.mouseLeave.apply(null, arguments)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "open-indicator",
                                    fn: function ({ attributes }) {
                                      return [
                                        _c(
                                          "IcArrowSelect",
                                          _vm._b(
                                            {},
                                            "IcArrowSelect",
                                            attributes,
                                            false
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.innerValue,
                                callback: function ($$v) {
                                  _vm.innerValue = $$v
                                },
                                expression: "innerValue",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(_vm._s(_vm.$t("common.no_options")))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "small",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.rules,
                                  expression: "rules",
                                },
                              ],
                              staticClass:
                                "text-danger small show_name_validate",
                            },
                            [_vm._v(_vm._s(errors[0]))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm.$attrs.isHalf === true
              ? _c("div", [
                  _vm.$attrs.label
                    ? _c("div", { staticClass: "mb-2" }, [
                        _c(
                          "span",
                          {
                            staticClass: "label",
                            style: {
                              color: _vm.$attrs.color || null,
                              fontSize: _vm.$attrs.fontSize || null,
                            },
                          },
                          [_vm._v(_vm._s(_vm.$attrs.label))]
                        ),
                        _vm._v(" "),
                        _vm.checkRequired
                          ? _c(
                              "span",
                              {
                                staticClass: "label",
                                staticStyle: { color: "#f46414" },
                              },
                              [_vm._v("*")]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form-group mb-0 __selected",
                      staticStyle: { background: "none" },
                    },
                    [
                      _c(
                        "client-only",
                        [
                          _c(
                            "v-select",
                            {
                              ref: "select",
                              staticClass: "ellipsedd",
                              attrs: {
                                label: _vm.$attrs["option-label"],
                                reduce: _vm.$attrs.reduce,
                                options: _vm.$attrs.options,
                                placeholder: _vm.$t("common.select"),
                                disabled: _vm.$attrs.disabled || false,
                                selectable: _vm.$attrs.selectable,
                                clearable: _vm.$attrs.clearable,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.optionSelected($event, flags)
                                },
                                "option:selected": function ($event) {
                                  return _vm.optionSelected($event, flags)
                                },
                                "search:focus": _vm.onFocus,
                              },
                              nativeOn: {
                                mouseenter: function ($event) {
                                  return _vm.mouseEnter.apply(null, arguments)
                                },
                                mouseleave: function ($event) {
                                  return _vm.mouseLeave.apply(null, arguments)
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "open-indicator",
                                  fn: function ({ attributes }) {
                                    return [
                                      _c(
                                        "IcArrowSelect",
                                        _vm._b(
                                          {},
                                          "IcArrowSelect",
                                          attributes,
                                          false
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.innerValue,
                                callback: function ($$v) {
                                  _vm.innerValue = $$v
                                },
                                expression: "innerValue",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(_vm._s(_vm.$t("common.no_options")))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.rules
                            ? _c(
                                "small",
                                {
                                  staticClass:
                                    "text-danger small show_name_validate",
                                },
                                [_vm._v(_vm._s(errors[0]))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }