var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationProvider", {
    staticClass: "form-group mb-0",
    attrs: {
      mode: _vm.$isIE ? "eager" : undefined,
      name: _vm.nameValidator,
      rules: _vm.rules,
      tag: "div",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _c(
              "base-input-login",
              _vm._b(
                {
                  staticClass: "mb-1",
                  attrs: {
                    placeholder: _vm.$attrs.placeholder,
                    keyupEnter: _vm.keyupEnter,
                    autocomplete: _vm.$attrs.autocomplete || "on",
                    disabled: _vm.$attrs.disabled,
                  },
                  on: { "change-type-password": _vm.changeTypePassword },
                  model: {
                    value: _vm.innerValue,
                    callback: function ($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue",
                  },
                },
                "base-input-login",
                _vm.$attrs,
                false
              )
            ),
            _vm._v(" "),
            _c(
              "small",
              { staticClass: "text-danger small show_name_validate" },
              [_vm._v(_vm._s(errors[0]))]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }