
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Compressor from 'compressorjs';
import axios from 'axios';

if (process.client) {
  var heic2any = require("heic2any");
}

class CustomUploadImageError extends Error {
  fileName: string;
  constructor(fileName, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomUploadImageError);
    }

    // this.name = 'CustomError'
    // Custom debugging information
    this.fileName = fileName;
  }
}

@Component({})
export default class BaseUploadImage extends Vue {
  @Prop({ type: String, required: false, default: '/api/v1/storages/multiple' }) baseUrl!: string;
  @Prop({ type: Number, required: false, default: 0.6 }) compressorQuality!: number;
  @Prop({ type: Number, required: false, default: 0 }) limitImage!: number;
  @Prop({ type: Number, required: false, default: 0 }) currentImage!: number;


  limitedFilesize(file: File) {
    const maxAllowedSize = 10 * 1024 * 1024;
    return file.size <= maxAllowedSize;
  }
  isFileImage(file) {
    if (!file) return false;
    // if (file.name.endsWith(".HEIC") || file.name.endsWith(".heic")) {
    //   return true;
    // }
    return /^image\//.test(file.type);
  }
  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  imageCompressor(files) {
    return new Promise<FormData>((resolve, reject) => {
      const data = new FormData();
      let countAppend = 0;
      const countImg = files.filter(file => this.isFileImage(file) || this.limitedFilesize(file))?.length ?? 0;
      const callback = (files) => {
        files.forEach(file => {
          if (!this.limitedFilesize(file) || !this.isFileImage(file)) {
            // reject(new CustomUploadImageError(file.name, 'IMGF101'));
            let messageError = this.$t('error.ERR_IMGF101').toString();
            messageError += `${file.name}`;
            this.$emit('upload-image-error', messageError);
            return;
          }
          // if (!this.isFileImage(file)) {
          //   reject(new CustomUploadImageError(file.name, 'IMGF101'));
          // }
          if (file.type.includes("svg")) {
            const dataImg = new Image();
            dataImg.src = URL.createObjectURL(file);
            dataImg.onload = () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              canvas.width = dataImg.width;
              canvas.height = dataImg.height;
              ctx?.drawImage(dataImg, 0, 0);

              const canvasUrl = canvas.toDataURL();
              const blob = this.dataURItoBlob(canvasUrl);
              file = new File([blob], file, { type: "image/jpeg" });
              new Compressor((file as File), {
                quality: this.compressorQuality,
                success(result) {
                  data.append('files', result, (result as any).name);
                  ++countAppend;
                  if (countImg === countAppend) {
                    resolve(data);
                  }
                },
                error: (err) => {
                  // reject(new CustomUploadImageError(file.name, 'IMGF101'));
                  ++countAppend;
                  const filesCallback = files.filter(_file => _file.name !== file.name);
                  callback(filesCallback);
                  let messageError = this.$t('error.ERR_IMGF101').toString();
                  messageError += `${file.name}`;
                  this.$emit('upload-image-error', messageError);
                }
              });
            }
          } else {
            new Compressor((file as File), {
              quality: this.compressorQuality,
              convertTypes: ['image/png','image/jpeg', 'image/jpg', 'image/webp'],
              convertSize: 1000000,
              success(result) {
                data.append('files', result, (result as any).name);
                ++countAppend;
                if (countImg === countAppend) {
                  resolve(data);
                }
              },
              error: (err) => {
                // reject(new CustomUploadImageError(file.name, 'IMGF101'));
                ++countAppend;
                const filesCallback = files.filter(_file => _file.name !== file.name);
                callback(filesCallback);
                let messageError = this.$t('error.ERR_IMGF101').toString();
                messageError += `${file.name}`;
                this.$emit('upload-image-error', messageError);
              }
            });
          }
        });
      }
      callback(files);
    });
  }
  getFileFromEvent(e) {
    try {
      if (e.files) {
        return Array.from(e.files);
      }
      if (e.dataTransfer) {
        return Array.from(e.dataTransfer.files);
      }
      if (e.target) {
        return Array.from(e.target.files);
      }
    } catch (error) {
    }
  }
  checkImage(images) {
    return new Promise((resolve, reject) => {
      images.forEach(image => {
        new Compressor((image as File), {
          quality: this.compressorQuality,
          success(result) {
          },
          error: (err) => {
            reject(image.name);
          }
        })
      })
      resolve(true);
    });
  }

  async pickFile(e) {
    try {
      let images = this.getFileFromEvent(e);
      this.$emit('handle-loading-upload', false);
      if (e.target) {
        e.target.value = '';
      }
      if (!images) {
        return;
      }
      if (!images.length) {
        return;
      }
      let checkImage: boolean = false;
      await this.checkImage(images).then((data) => {
        checkImage = true;
      }).catch((e) => {
        let messageError = this.$t('error.ERR_IMGF101').toString();
        messageError += e;
        this.$emit('upload-image-error', messageError);
      })
      if (images.length + this.currentImage > (this.limitImage || 10) && checkImage) {
        this.$emit('limit-upload-image', {
          title: this.$t('common.notification').toString(),
          message: this.$t('forums.limit_upload_image_question_message', { 0: this.limitImage }).toString()
        });
        return;
      }
      
      const images2 = await this.checkFileHEIC(images)
      this.$emit('before-upload-image');
      
      const data = await this.imageCompressor(images2);
      // let data2 = new FormData();
      // images.forEach(image => {
      //   data2.append('files', image, image.name);
      // })
      const _rs = await axios.create({ baseURL: process.env.BASE_URL_API }).post(this.baseUrl, data,
        { headers: { "Content-Type": "multipart/form-data" } });
      this.$emit('handle-loading-upload', true);

      const result = _rs['data']['data'];
      this.$emit('upload-image-success', result);
      if (e.target) {
        e.target.value = '';
      }

      return result;
    } catch (error) {
      if (e.target) {
        e.target.value = '';
      }
      if (!(error as any).response) {
        let messageError = this.$t(`error.${(error as any).message}`).toString();
        messageError += `${(error as any).fileName}`;
        this.$emit('handle-loading-upload', false);
        this.$emit('upload-image-error', messageError);
        return;
      }
      this.$handleErrorApi(error, (errCode) => {
        const messageError = this.$t(`error.${errCode}`).toString();
        this.$emit('handle-loading-upload', false);
        this.$emit('upload-image-error', messageError);
      });
    }
  }
  async checkFileHEIC(files) {
    let files2: any = [];

    return new Promise((resolve, reject) => {
      files.forEach((file, index) => {
        if (file.name.endsWith(".HEIC") || file.name.endsWith(".heic")) {
          heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.3, // cuts the quality and size by half
          }).then(blob => {
            const fileConvertType = new File([blob], file.name, { type: 'image/jpeg' });
            file = fileConvertType;
            files2.push(file);
            if (index == (files.length - 1)) {
              resolve(files2)
            }
          })
        } else {
          files2.push(file);
          if (index == (files.length - 1)) {
            resolve(files2)
          }
        }
      })
    })
  }
  render() {
    return (this.$scopedSlots as any).default({
      UploadImage: this.pickFile
    });
  }
}
