
import { Vue, Component, Prop, Watch, mixins } from "nuxt-property-decorator";
import { ValidationProvider } from "vee-validate";
import EventDOMCustom from "~/mixins/EventDOMCustom.vue";
import IcArrowSelect from "~/assets/icons/IcArrowSelect.vue";

@Component({
  components: {
    ValidationProvider,
    IcArrowSelect,
  },
  data: () => ({
    innerValue: null,
  }),
})
export default class VSelectWithValidation extends mixins<EventDOMCustom>(
  EventDOMCustom
) {
  innerValue: any = null;

  @Prop({ type: [Object, String, Number, Boolean], default: "" }) rules;
  @Prop({ type: [Object, String, Number, Boolean], default: null }) value;

  @Watch("innerValue")
  onInnerValueChanged(newVal: any, oldVal: any) {
    this.$emit("input", newVal);
    this.$emit("onSelect", newVal);
  }

  @Watch("value")
  onValueChanged(newVal: any, oldVal: any) {
    if (typeof newVal === "object" && newVal !== null) {
      return;
    }
    this.innerValue = newVal;
  }
  created() {
    if (
      (this.value && typeof this.value != "object") ||
      (typeof this.value === "boolean" && this.value === false)
    ) {
      this.innerValue = this.value;
    }
  }
  //delete value in view
  resetValue() {
    if (this.$refs.select != undefined) {
      this.$refs.select["_data"]["_value"] = null;
    }
  }
  get checkRequired() {
    if (typeof this.rules == "object") {
      return Object.keys(this.rules).includes("required");
    }
    if (typeof this.rules == "string") {
      return this.rules.includes("required");
    }
  }
  optionSelected(e, error) {
    setTimeout(() => {
      const obj = {
        value: e,
        error: error.invalid,
      };
      this.$emit("optionSelected", obj);
    }, 100);
  }
}
