
import { Vue, Component, Prop, Watch } from "nuxt-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: {
    ValidationProvider
  }
})
export default class VFileUploadWithValidation extends Vue {
  files: any = [];
  progressUpload: number = 0;
  innerValue: string = "";
  fileName: string = "";
  uploading: boolean = false;
  uploadEnd: boolean = false;

  @Prop({ type: [Object, String], default: "" }) rules;
  @Prop({ type: String }) value;

  @Watch("innerValue")
  onInnerValueChanged(newVal: string, oldVal: string) {
    this.$emit("input", newVal);
  }

  @Watch("value")
  onValueChanged(newVal: string, oldVal: string) {
    this.innerValue = newVal;
  }
  onChange(value){
    this.files = value;
  }
  async openFile() {
    let response = await fetch(this.innerValue,{mode:"no-cors"});
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg"
    };
    
    const file = new File([data], "test.jpg", metadata);
    this.files = [file];
  }
  created() {
    if (this.value) {
      this.innerValue = this.value.toString();
      this.uploadEnd = true;
      this.openFile();
    }
  }

  async inputFilter(newFile, oldFile, prevent) {
    if (newFile && !oldFile) {
      if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
        alert("Your choice is not a picture");
        return prevent();
      }
    }
    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
      newFile.url = "";
      const URL = window.URL || (window as any).webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.url = URL.createObjectURL(newFile.file);
        this.fileName = newFile.file.name;
        this.uploading = true;
        const config = {
          onUploadProgress: progressEvent => {
            this.progressUpload = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          }
        };
        const _self = this;
        if (process.browser) {
          let Compressor = require("compressorjs");
          new Compressor(newFile.file, {
            quality: .3,
            success(result) {
              let data = new FormData();
              data.append("file", result, (result as any).name);
              _self.$axios.post('api/v1/storages', data, config).then((res) => {
              _self.uploadEnd = true;
              _self.uploading = false;
              _self.innerValue = res.data.data;
              });
            },
            error(e) {
              // TODO : handle Error
            },
          });
        }
      }
    }
  }

  async deleteImage() {
    this.uploading = false;
    this.uploadEnd = false;
    this.files = [];
    this.$axios.delete("api/v1/storages",{
      params: {
        'url': this.innerValue
      }
    }).then(res => {
      if (res.status == 200) {
        this.innerValue = "";
      }
    });
  }
}
