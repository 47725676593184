
import { Vue, Component, Prop, Watch, mixins } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import EventDOMCustom from '~/mixins/EventDOMCustom.vue';

@Component({
  components: {
    ValidationProvider
  }
})
export default class VTextFieldWithValidation extends mixins<EventDOMCustom>(EventDOMCustom) {
  innerValue: string = '';
  doneInit: Boolean = false;

  @Prop({ type: [Object, String], default: '' }) rules;
  @Prop({ default: null }) value;

  @Watch('innerValue')
  onInnerValueChanged(newVal: string, oldVal: string) {
    if(this.doneInit){
      this.$emit('input', this.processInput(newVal))
    }
  }

  @Watch('value')
  onValueChanged(newVal: string, oldVal: string) {
    this.innerValue = this.processInput(newVal)
  }

  created() {
    if ((typeof this.value == 'string' && this.value) || (typeof this.value == 'number')) {
      this.innerValue = ''+this.value;
    }
    this.$nextTick(()=>{
      this.doneInit = true;
    })
  }
  get checkRequired() {
    if (typeof this.rules == 'object') {
      return Object.keys(this.rules).includes('required');
    }
    if (typeof this.rules == 'string') {
      return this.rules.includes('required');
    }
  }

  processInput(newValOriginal: string) {
    let newVal = newValOriginal;
    if (typeof newValOriginal != "string") newVal = newValOriginal + "";
    if (
      this.$attrs.type === "number" &&
      (newVal.includes("１") ||
        newVal.includes("２") ||
        newVal.includes("３") ||
        newVal.includes("４") ||
        newVal.includes("５") ||
        newVal.includes("６") ||
        newVal.includes("７") ||
        newVal.includes("８") ||
        newVal.includes("９") ||
        newVal.includes("０"))
    ) {
      let newValReplaceAllJapaneseCharacter = newVal;
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("１", "1");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("２", "2");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("３", "3");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("４", "4");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("５", "5");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("６", "6");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("７", "7");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("８", "8");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("９", "9");
      newValReplaceAllJapaneseCharacter =
        newValReplaceAllJapaneseCharacter.replaceAll("０", "0");
      return newValReplaceAllJapaneseCharacter;
    }
    return newVal;
  }
  onPasteVal(e) {
    window.document.execCommand('insertText',false,e.clipboardData.getData('Text').replace(/[^0-9]+/g,''));
  }
}
