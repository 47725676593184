var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationProvider", {
    attrs: { name: _vm.$attrs.name, rules: _vm.rules, tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors, validate }) {
          return [
            _c("div", { staticClass: "__avatar" }, [
              _c(
                "div",
                {
                  staticClass: "__label",
                  style: {
                    color: _vm.$attrs.color,
                    fontSize: _vm.$attrs.fontSize,
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$attrs.label))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "label", staticStyle: { color: "#f46414" } },
                    [_vm._v("*")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "client-only",
                    [
                      _vm.showUpload
                        ? _c(
                            "v-focus-trap",
                            {
                              directives: [
                                {
                                  name: "scroll-lock",
                                  rawName: "v-scroll-lock",
                                  value: _vm.showUpload,
                                  expression: "showUpload",
                                },
                              ],
                            },
                            [
                              _c("my-upload", {
                                ref: "uploadImg",
                                attrs: {
                                  field: "file",
                                  value: _vm.showUpload,
                                  width: _vm.sizeConfig.width,
                                  height: _vm.sizeConfig.height,
                                  url: _vm.getBaseUrl,
                                  "lang-ext": _vm.getObjectLanguage,
                                  "lang-type": _vm.$i18n.locale,
                                  name: _vm.$attrs.name,
                                  maxSize: 10240,
                                  "img-format": "jpeg",
                                  tabIndex: -1,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.showUpload = $event
                                  },
                                  "crop-success": _vm.cropSuccess,
                                  "crop-upload-success": _vm.cropUploadSuccess,
                                  "crop-upload-fail": _vm.cropUploadFail,
                                  input: function ($event) {
                                    _vm.fnInput($event)
                                    validate(_vm.innerValue)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.innerValue,
                        expression: "innerValue",
                      },
                    ],
                    staticClass: "position-absolute",
                    staticStyle: { opacity: "0", width: "0" },
                    attrs: { type: "text", tabindex: "0" },
                    domProps: { value: _vm.innerValue },
                    on: {
                      focus: function ($event) {
                        return _vm.fnInput($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.innerValue = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.innerValue
                    ? _c("img", {
                        style:
                          _vm.$attrs.size &&
                          Object.keys(_vm.$attrs.size).length > 0
                            ? "width: 100%; height:auto"
                            : "width: 300px; height: 300px",
                        attrs: { src: _vm.innerValue, alt: _vm.innerValue },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "area__selected-upload",
                          on: {
                            click: _vm.toggleShow,
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.onDrop.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "avatar_anonymous",
                            attrs: {
                              src: require("assets/icons/icon-upload-image.svg"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "__title" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("message.drag_and_drop_file_upload")
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "__intro" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "message.file_upload_description",
                                    _vm.sizeConfig
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: errors[0],
                          expression: "errors[0]",
                        },
                      ],
                    },
                    [
                      _c(
                        "small",
                        { staticClass: "text-danger small show_name_validate" },
                        [_vm._v(_vm._s(errors[0]))]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.innerValue
                ? _c(
                    "div",
                    {
                      staticClass: "__btn mt-4 btn_system_action_primary_hover",
                      on: { click: _vm.toggleShow },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("candidate.update")) +
                            "\n      "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }