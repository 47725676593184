var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationProvider", {
    staticClass: "row",
    attrs: { name: _vm.$attrs.name, rules: _vm.rules, tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors, validate }) {
          return [
            _c("div", { staticClass: "col-md-6" }, [
              _c("p", { staticClass: "mb-0 font-weight-700 text-uppercase" }, [
                _vm._v(_vm._s(_vm.$attrs.label) + "\n      "),
                _c(
                  "span",
                  { staticClass: "text-danger font-weight-bold font-10" },
                  [_vm._v("*")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group mb-0" },
                [
                  _c("client-only", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-group mb-0 avatar-upload d-flex flex-column p-2",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "upload-area justify-content-center align-items-center d-flex",
                            attrs: { for: _vm.$attrs.unique },
                          },
                          [
                            _vm.uploading === true
                              ? _c("p", [
                                  _vm._v(_vm._s(_vm.progressUpload + "%")),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.uploadEnd === true
                              ? _c("img", {
                                  staticClass: "mw-100",
                                  attrs: {
                                    alt: _vm.$attrs.label,
                                    src: _vm.innerValue,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.uploadEnd === false && _vm.uploading === false
                              ? _c("div", { staticClass: "text-center" }, [
                                  _c("i", { staticClass: "ni ni-image mb-2" }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "mb-0 font-weight-bold h6" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("common.upload_file_guild")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("common.upload_file_limitation")
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.uploadEnd === false && _vm.uploading === false
                          ? _c("file-upload", {
                              ref: "upload",
                              staticClass: "d-none",
                              attrs: {
                                value: _vm.files,
                                extensions: "gif,jpg,jpeg,png,webp",
                                accept:
                                  "image/png, image/gif, image/jpeg, image/webp",
                                name: _vm.$attrs.unique,
                                "post-action": "/upload/post",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.onChange($event) || validate($event)
                                },
                                "input-filter": _vm.inputFilter,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-danger small" }, [
                          _vm._v(_vm._s(errors[0])),
                        ]),
                        _vm._v(" "),
                        _vm.uploadEnd
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-danger mt-0 align-self-center",
                                attrs: { type: "button" },
                                on: { click: _vm.deleteImage },
                              },
                              [_vm._v(_vm._s(_vm.$t("common.delete_image")))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }