
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';

@Component({})
export default class ResizeAutoTextareaIE extends Vue {
  @Prop({type: Number, required: false, default: 0}) minHeight!: number;
  @Prop({ type: [Number, String], default: '' }) value;
  innerValue = '';

  created() {
    this.innerValue = this.value;
  }

  mounted() {
    this.$nextTick(() => {
   		this.$el?.dispatchEvent(new Event("keyup"));
    });
  }

  @Watch('innerValue')
  onInnerValueChanged(newVal: string, oldVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  onValueChanged(newVal: string, oldVal: string) {
    this.innerValue = newVal;
    !newVal && this.resizeTextarea(this.$el);
  }
  resizeTextarea(e) {
    const area = e.target || this.$el;
    const heightVal = this.minHeight >= area.scrollHeight ? this.minHeight : area.scrollHeight;
    area.style.height = heightVal + 'px';
    this.$nextTick(() => {
      area.style.height = this.minHeight > 0 ? this.minHeight + 'px' : 'auto';
      const contentHeight = this.minHeight >= area.scrollHeight ? area.scrollHeight : 
                              (area.scrollHeight)
                              // (area.scrollHeight + (+this.$attrs.padding || 0))
      area.style.height = contentHeight + 'px';
    });
  }
  get computedStyles() {
    return {
      minHeight: this.minHeight + 'px !important',
      height: this.minHeight ? this.minHeight + 'px !important' : 'auto',
      resize: 'none',
      boxSizing: 'border-box'
    }
  }
}
