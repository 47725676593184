
import { Vue, Component, Prop, Watch } from "nuxt-property-decorator";
import { ValidationProvider } from "vee-validate";
import IcArrowSelect from "~/assets/icons/IcArrowSelect.vue";

@Component({
  components: {
    ValidationProvider,
    IcArrowSelect,
  },
  data: () => ({
    innerValue: null,
  }),
})
export default class VSelectCheckboxWithValidation extends Vue {
  innerValue: any = null;

  @Prop({ type: [Object, String, Number], default: "" }) rules;
  @Prop({ type: [Object, String, Number, Array], default: null }) value;
  @Prop({ type: Array, default: () => [] }) currentValue;
  @Prop({ required: true }) options;
  @Prop({ type: [Object, String, Number], default: "" }) currentCategory;

  isOption = false;
  resultIds: String[] = [];
  oldResultIds: String[] = [];
  doneInit = false;

  created() {
    if (
      this.currentValue &&
      this.currentValue.length > 0 &&
      this.currentCategory
    ) {
      this.resultIds = this.currentValue;
      this.oldResultIds = this.currentValue;
    }
    this.$nextTick(() => {
      this.doneInit = true;
    });
  }
  get isCompareIds() {
    return (
      (this.resultIds?.length ?? 0) === (this.oldResultIds?.length ?? 0) &&
      this.getItemNames
    );
  }
  showOption() {
    this.isOption = !this.isOption;
  }
  closeOption() {
    this.isOption = false;
  }
  onClickItem(id: String) {
    if (this.resultIds.includes(id)) {
      this.resultIds.splice(this.resultIds.indexOf(id), 1);
    } else {
      this.resultIds.push(id);
    }
  }
  get getItemNames() {
    let res: string[] = [];
    if (this.options.length > 0) {
      this.options.forEach((op) => {
        if (this.resultIds.includes(op.id)) {
          res.push("#" + op.name);
        }
      });
    }
    return res.join(", ");
  }
  @Watch("currentCategory")
  onChangeCurrentCategory(newVal: string, oldVal: string) {
    if (oldVal && newVal !== oldVal) {
      this.$emit("change-industry", this.resultIds);
    }
  }
  @Watch("resultIds")
  onInnerValueChanged(newVal: String[], oldVal: String[]) {
    if (this.doneInit) {
      this.$emit("input", newVal);
      this.$emit("onChange", newVal);
    }
  }

  @Watch("value")
  onValueChanged(newVal: any, oldVal: any) {
    this.innerValue = newVal;
    this.resultIds = newVal;
  }

  //delete value in view
  resetValue() {
    if (this.$refs.select != undefined) {
      this.$refs.select["_data"]["_value"] = null;
    }
  }
  get checkRequired() {
    if (typeof this.rules == "object") {
      return Object.keys(this.rules).includes("required");
    }
    if (typeof this.rules == "string") {
      return this.rules.includes("required");
    }
  }
}
