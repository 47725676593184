
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'

import BaseInputLogin from '~/argon-components/BaseInputLogin.vue'

@Component({
  components: {
    ValidationProvider,
    BaseInputLogin
  },
  data: () => ({
    innerValue: ''
  })
})
export default class VTextFieldType2WithValidation extends Vue {
  innerValue: string = '';

  @Prop({ type: [Object, String], default: '' }) rules;
  @Prop({ default: null }) value;
  @Prop({type: Function}) keyupEnter;

  @Watch('innerValue')
  onInnerValueChanged(newVal: string, oldVal: string) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  onValueChanged(newVal: string, oldVal: string) {
    this.innerValue = newVal
  }

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
  get nameValidator() {
    return this.$i18n.locale === 'en' ? this.$attrs?.name?.toLowerCase() : this.$attrs?.name;
  }
  changeTypePassword() {
    this.$emit('change-type-password');
  }
}
