var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationProvider", {
    attrs: { name: _vm.$attrs.name, rules: _vm.rules, tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors, validate }) {
          return [
            _c("div", [
              _c("div", { staticClass: "mt-2" }, [
                _vm.$attrs.label
                  ? _c("div", { staticClass: "mb-2" }, [
                      _c(
                        "span",
                        {
                          staticClass: "label",
                          staticStyle: { "font-size": "16px" },
                        },
                        [_vm._v(_vm._s(_vm.$attrs.label))]
                      ),
                      _vm._v(" "),
                      _vm.checkRequired
                        ? _c(
                            "span",
                            {
                              staticClass: "label",
                              staticStyle: {
                                color: "#f46414",
                                "font-size": "16px",
                              },
                            },
                            [_vm._v("*")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group mb-0 __selected" },
                  [
                    _c("client-only", [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.closeOption,
                              expression: "closeOption",
                            },
                          ],
                          staticClass:
                            "selected-type-search d-flex position-relative bg-white",
                          class: [{ "custom-selected-type": _vm.isOption }],
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "show-type flex-1",
                              on: { click: _vm.showOption },
                            },
                            [
                              !_vm.getItemNames
                                ? _c(
                                    "span",
                                    {
                                      class: [
                                        {
                                          "custom-placehold": !_vm.getItemNames,
                                        },
                                      ],
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.select")))]
                                  )
                                : _c("span", { staticClass: "hash-tag" }, [
                                    _vm._v(_vm._s(_vm.getItemNames)),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "icon-select mr-2",
                              class: { "rotate-icon": _vm.isOption },
                              on: { click: _vm.showOption },
                            },
                            [_c("IcArrowSelect")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isOption,
                                  expression: "isOption",
                                },
                              ],
                              staticClass: "show-result-option",
                            },
                            _vm._l(_vm.options, function (option, i) {
                              return _c(
                                "span",
                                {
                                  key: i,
                                  staticClass:
                                    "container-cb d-flex flex-column",
                                  attrs: { "data-option": option.id },
                                  on: {
                                    click: function ($event) {
                                      _vm.onClickItem(option.id), validate()
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.resultIds,
                                        expression: "resultIds",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      value: option.id,
                                      checked: Array.isArray(_vm.resultIds)
                                        ? _vm._i(_vm.resultIds, option.id) > -1
                                        : _vm.resultIds,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.resultIds,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = option.id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.resultIds = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.resultIds = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.resultIds = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "content-checkbox" },
                                    [_vm._v(_vm._s(option.name))]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "small",
                        { staticClass: "text-danger small show_name_validate" },
                        [_vm._v(_vm._s(errors[0]))]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }