
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import flatPicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import BaseInputCustom from '~/argon-components/BaseInputCustom.vue'

@Component({
  components: {
    ValidationProvider,
    flatPicker,
    BaseInputCustom
  }
})
export default class VDatePickerWithValidation extends Vue {
  innerValue: string = '';

  @Prop({ type: [Object, String], default: '' }) rules;
  @Prop({ default: null }) value;
  
  get configDatePicker() {
    const _config = { disableMobile: true, allowInput: false, mode: `${this.$attrs.mode}`, wrap: true, static: true, altInput: true, format: 'Y-m-d', altFormat: this.formatDateInput(), onClose: this.onClose};
    if (this.$attrs.minDate) {
      (_config as any).minDate = this.$attrs.minDate;
    }
    if (this.$attrs.maxDate) {
      (_config as any).maxDate = this.$attrs.maxDate;
    } else {
      (_config as any).maxDate = '9999/12/31'
    }
    if (this.$attrs.position) {
      (_config as any).position = this.$attrs.position;
    }
    return _config;
  }
  @Watch('innerValue')
  onInnerValueChanged(newVal: string, oldVal: string) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  onValueChanged(newVal: string, oldVal: string) {
    this.innerValue = newVal
  }

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
    if (this.$attrs.maxDate == 'today') {
      this.innerValue = this.$moment(this.innerValue).format('YYYY-MM-DD');
    }
  }
  onClose(selectedDates, dateStr, instance){
    instance.setDate(dateStr);
  }

  formatDateInput() {
    let _format = 'm/d/Y';
    if(this.$i18n.locale === 'vi'){
      _format = 'd/m/Y';
    } else if(this.$i18n.locale === 'ja'){
      _format = 'Y/m/d'
    }
    return _format; 
  }

  chooseDay(){
    (this.$refs.datePicker as any).fp.open();
  }
}
